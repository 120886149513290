import {useState} from 'react';
import Form from 'react-bootstrap/Form';

// for every non-plot layer
export function FilterLineForm({availableKeys, defaults, handleUpdate, submit}) {
    const [condition, setCondition] = useState(defaults.condition);
    const [keysNextLevel, setKeysNextLevel] = useState(availableKeys[defaults.key]);
    const [key, setKey] = useState(defaults.key);
    const [keyNextLevel, setKeyNextLevel] = useState(defaults.keyNextLevel);
    const [value, setValue] = useState(defaults.value);

    const update = values => {
        let localCondition;
        let localKey;
        let localKeyNextLevel;
        let localValue;

        if ('condition' in values) {
            localCondition = values.condition;
            setCondition(localCondition);
        } else {
            localCondition = condition;
        }

        if ('key' in values) {
            localCondition = '';
            localKey = values.key;
            localKeyNextLevel = '';
            localValue = '';
            setCondition(localCondition);
            setKey(localKey);
            setKeyNextLevel(localKeyNextLevel);
            setKeysNextLevel(0 < availableKeys[localKey].length ? availableKeys[localKey] : []);
            setValue(localValue);
        } else {
            localKey = key;
        }

        if ('keyNextLevel' in values) {
            localKeyNextLevel = values.keyNextLevel;
            setKeyNextLevel(localKeyNextLevel);
        } else {
            localKeyNextLevel = keyNextLevel;
        }

        if ('value' in values) {
            localValue = values.value;
            setValue(localValue);
        } else {
            localValue = value;
        }

        handleUpdate({
            'condition': localCondition,
            'key': localKey,
            'keyNextLevel': localKeyNextLevel,
            'value': localValue,
        });
    };

    return (
        <Form.Group className="mb-3">
            <Form.Select
                name="key"
                onChange={event => {
                    update({'key': event.target.value});
                }}
                size="sm"
                value={key}
            >
                <option key="" value="" />
                {Object.keys(availableKeys).map(localKey =>
                    <option key={localKey} value={localKey}>{localKey}</option>
                )}
            </Form.Select>
            {undefined !== keysNextLevel && 0 < keysNextLevel.length &&
                <Form.Select
                    className="mt-1"
                    name="keyNextLevel"
                    onChange={event => update({'keyNextLevel': event.target.value})}
                    size="sm"
                    value={keyNextLevel}
                >
                    <option key="" value="" />
                    {keysNextLevel.map(localKey =>
                        <option key={localKey} value={localKey}>{localKey}</option>
                    )}
            </Form.Select>}
            <Form.Select
                className="mt-1"
                name="condition"
                onChange={event => update({'condition': event.target.value})}
                size="sm"
                value={condition}
            >
                {[{
                    'key': '',
                    'name': '',
                }, {
                    'key': 'eq',
                    'name': '=',
                }, {
                    'key': 'gte',
                    'name': '>=',
                }, {
                    'key': 'lte',
                    'name': '<=',
                }, {
                    'key': 'incl',
                    'name': '⊃',
                }, {
                    'key': 'not',
                    'name': '≠',
                }].map(option => <option key={option.key} value={option.key}>{option.name}</option>)}
            </Form.Select>
            <Form.Control
                className="mt-1"
                name="value"
                onChange={event => update({'value': event.target.value})}
                onKeyDown={event => {
                    if (event.key === 'Enter') {
                        submit();
                    }
                }}
                size="sm"
                type="text"
                value={value}
            />
        </Form.Group>
    );
}
